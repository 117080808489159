import React, { Component, componentDidUpdate } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-block-ui/style.css';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { checkItsNotLoggedIn, postData, reFreashReactTable, toastMessageShow, AjaxConfirm, css } from 'service/common.js';
import '../../../scss/components/admin/salesforce/lightning/salesforce-lightning-design-system.scss';
import SLDSReactTable from '../../../salesforce/lightning/SLDSReactTable'
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import { ROUTER_PATH, COMMON_DOC_DOWNLOAD_URL,FILE_DOWNLOAD_MODULE_NAME } from 'config.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { defaultSpaceInTable } from 'service/custom_value_data.js';

import {
    Card,
    Button,
    Dropdown,
    Icon,
    IconSettings,
} from '@salesforce/design-system-react';
import CreateDocumentModel from './CreateDocumentModel.jsx';
import EditDocumentModel from './EditDocumentModel.jsx';

import '../../../scss/components/admin/member/member.scss';
import { getDocumentListByPage } from '../../actions/MemberAction';
import ArchiveModal from '../../../oncallui-react-framework/view/Modal/ArchiveModal';

/**
 * Class: DocumentCard
 */
class DocumentCard extends Component {

    constructor(props) {
        super(props);
        // Check user is logged in or not
        checkItsNotLoggedIn();
        // Initialize state
        this.state = {
            loading: false,
            redirectPage: false,
            document_count: 0,
            openCreateDocument: false,
            openEditDocument: false,
            searchVal: '',
            filterVal: '',
            documentList: [],
            pageSize: 6,
            page: 0,
            document_id: '',
            validStatus: 1,
        }
        // Initilize react table
        this.reactTable = React.createRef();
    }

    /*
     * method runs after the component output has been rendered to the DOM
     */
    componentDidMount() {
        this.fetchDocumentData(this.state);
    }

    componentDidUpdate(prevProps) {

        if (this.props.refresh_doc_list != prevProps.refresh_doc_list) {
            if (this.props.refresh_doc_list) {
                this.fetchDocumentData(this.state);
            }

        }
    }


    /**
     * Call the requestData
     * @param {temp} state 
     */
    fetchDocumentData = (state) => {
        this.setState({ loading: true });
        getDocumentListByPage(
            this.props.entity_id,
            this.props.url,
            this.props.user_page,
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            this.setState({
                documentList: res.rows,
                document_count: res.count,
                pages: res.pages,
                loading: false
            }, () => {
                if (this.props.user_page == 'member') {
                    this.props.refreshDocDetails();
                }

            });
        });
    }

    /**
     * Table columns
     * @returns {(import('react-table').Column & { _label: string })[]}
     */
    determineColumns() {
        const styles = css({
            hyperlink: {
                color: 'rgb(0, 112, 210)'
            }
        });
        return [
            {
                _label: 'File Name',
                accessor: "file_name",
                className: "slds-tbl-card-td-link-hidden",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => {
                    if (!props.original.file_path || props.original.file_path == '') {
                        return <span className="vcenter slds-truncate">{defaultSpaceInTable(COMMON_DOC_DOWNLOAD_URL + FILE_DOWNLOAD_MODULE_NAME.mod3 + '/?url=' + props.value)}</span>
                    }
                    return (
                        <span className="vcenter slds-truncate"><a style={styles.hyperlink} className="reset" title="View/download" target="_blank" href={defaultSpaceInTable(COMMON_DOC_DOWNLOAD_URL + FILE_DOWNLOAD_MODULE_NAME.mod3 + '/?url=' + props.original.file_path)}>{defaultSpaceInTable(props.value)}</a></span>
                    );
                }
            },
            {
                _label: 'Doc Type',
                accessor: "document",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="slds-truncate">{defaultSpaceInTable(props.value)}</span>,
            },
            {
                _label: 'Status',
                accessor: "status",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="slds-truncate">{defaultSpaceInTable(props.value)}</span>,
            },
            {
                _label: 'Issue Date',
                accessor: "issue_date",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => {
                    if (!props.value) {
                        return <span className="vcenter slds-truncate"></span>
                    }

                    const dateMoment = moment(props.value)
                    if (!dateMoment.isValid()) {
                        return <span className="vcenter slds-truncate"></span>
                    }

                    let tooltip = dateMoment.format('dddd, DD MMMM YYYY')
                    let value = dateMoment.format('DD/MM/YYYY')

                    return (
                        <span className="vcenter slds-truncate" title={tooltip}>{defaultSpaceInTable(value)}</span>
                    )
                }
            },
            {
                _label: 'Expiry Date',
                accessor: "expiry_date",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => {
                    if (!props.value) {
                        return <span className="vcenter slds-truncate"></span>
                    }

                    const dateMoment = moment(props.value)
                    if (!dateMoment.isValid()) {
                        return <span className="vcenter slds-truncate"></span>
                    }

                    let tooltip = dateMoment.format('dddd, DD MMMM YYYY')
                    let value = dateMoment.format('DD/MM/YYYY')

                    return (
                        <span className="vcenter slds-truncate" title={tooltip}>{defaultSpaceInTable(value)}</span>
                    )
                }
            },
            {
                _label: 'Reference Number',
                accessor: "reference_number",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="slds-truncate">{defaultSpaceInTable(props.value)}</span>,
            },
            {
                _label: 'Action',
                accessor: "",
                className: "slds-tbl-card-td slds-tbl-card-td-dd",
                sortable: false,
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props =>
                    <Dropdown
                        assistiveText={{ icon: 'More Options' }}
                        iconCategory="utility"
                        iconName="down"
                        iconVariant="border-filled"
                        disabled={Number(props.original.system_gen_flag) === 1 && this.state.validStatus === Number(props.original.document_status) ? true : false}
                        onSelect={(e) => {
                            if (e.value == 1) { //edit
                                this.showEditDocumentModal(props.original.id)
                            }
                            else { // delete
                                this.showDocumentArchiveModal(props.original.id);;
                            }
                        }}
                        className={'slds-more-action-dropdown'}
                        options={[
                            { label: 'Edit', value: '1' },
                            { label: 'Delete', value: '2' },
                        ]}
                    />,
            },
        ]
    }

    /**
     * Render document table if count greater than 0
     */
    renderTable() {
        if (this.state.document_count === 0) {
            return <React.Fragment />
        }

        return (
            <SLDSReactTable
                PaginationComponent={() => <React.Fragment />}
                ref={this.reactTable}
                manual="true"
                loading={this.state.loading}
                pages={this.state.pages}
                data={this.state.documentList}
                defaultPageSize={6}
                minRows={1}
                sortable={false}
                resizable={true}
                onFetchData={this.fetchDocumentData}
                columns={this.determineColumns()}
                getTableProps={() => ({ className: 'slds-table slds-table_cell-buffer slds-table_bordered slds-table_striped slds-tbl-card' })}
            />
        )
    }

    /**
     * Render view all if count greater than 0
     */
    renderViewAll = () => {
        if (this.state.document_count === 0) {
            return <React.Fragment />
        }

        return (
            <div className={'slds-align_absolute-center pt-2'}>
                {this.props.user_page=='member' ? <Link to={ROUTER_PATH + `admin/support_worker/document/${this.props.entity_id}`} className="reset" style={{ color: '#0070d2' }}>
                    {'View All'}
                </Link> : <Link to={ROUTER_PATH + `admin/item/participant/document/${this.props.entity_id}`} className="reset" style={{ color: '#0070d2' }}>
                    {'View All'}
                </Link>}
            </div>
        );
    }

    /**
     * Show update document model
     */
    showEditDocumentModal = (id) => {
        this.setState({
            openEditDocument: true,
            document_id: id,
        });
    }

    /**
     * Close the modal when user save the document and refresh the table
     * Get the Unique reference id
     */
    closeEditDocumentModal = (status, documentId) => {
        this.setState({ openEditDocument: false });

        if (status) {
            if (documentId) {
                // to do...
            } else {
                if (this.state.document_count === 0) {
                    this.fetchDocumentData(this.state);
                } else {
                    reFreashReactTable(this, 'fetchDocumentData');
                }

            }
        }
    }

    /**
    * Open archive document modal
    */
    showDocumentArchiveModal(document_id) {
        this.setState({ showDocumentArchiveModal: true, archive_document_id: document_id });
    }
    /**
     * Close archive document modal
     */
    closeArchiveModal = () => {
        this.setState({ showDocumentArchiveModal: false, archive_document_id: '' })
        reFreashReactTable(this, 'fetchDocumentData');
    }
    /**
     * Close the modal when user save the document and refresh the table
     * Get the Unique reference id
     */
    closeModal = (status, documentId) => {
        this.setState({ openCreateDocument: false });

        if (status) {
            if (documentId) {
                // to do...
            } else {
                if (this.state.document_count === 0) {
                    this.fetchDocumentData(this.state);
                } else {
                    reFreashReactTable(this, 'fetchDocumentData');
                }

            }
        }
    }

    /**
     * Render modals
     * - Create Document
     * - Edit Document
     * 
     */
    renderModals() {
        return (
            <React.Fragment>
                {
                    this.state.openCreateDocument && (
                        <CreateDocumentModel
                            showModal={this.state.openCreateDocument}
                            closeModal={this.closeModal}
                            headingTxt="New Document"
                            {...this.props}
                        />
                    )
                }
                {
                    this.state.openEditDocument && (
                        <EditDocumentModel
                            document_id={this.state.document_id}
                            showModal={this.state.openEditDocument}
                            closeModal={this.closeEditDocumentModal}
                            location_id={this.state.document_id}
                            headingTxt="Update Document"
                            {...this.props}
                        />
                    )
                }
                {this.state.showDocumentArchiveModal && <ArchiveModal
                    id={this.state.archive_document_id}
                    msg={'Document'}
                    content={'Are you sure you want to archive this document?'}
                    confirm_button={'Archive Document'}
                    api_url={'member/MemberDocument/archive_document'}
                    close_archive_modal={this.closeArchiveModal}
                    on_success={() => this.closeArchiveModal()}
                />
                }
            </React.Fragment>
        )
    }

    /**
     * Render the display content
     */
    render() {
        return (
            <React.Fragment>
                <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                    <Card
                        headerActions={<Button label="New" onClick={() => this.setState({ openCreateDocument: true })} />}
                        heading={Number(this.state.document_count) > 6 ? "Documents (6+)" : "Documents (" + this.state.document_count + ")"}
                        className="slds-card-bor"
                        icon={<Icon category="standard" name="file" size="small" />}
                    >
                        {this.renderTable()}
                        {this.renderViewAll()}
                    </Card>
                    {this.renderModals()}
                </IconSettings>
            </React.Fragment>
        );
    }
}


// Defalut Prop
ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
export default DocumentCard;
