import React from 'react';
import {  OCS_LOGO } from 'service/OcsConstant.js';
import {
    IconSettings,
    Icon,
} from '@salesforce/design-system-react';
import { css } from './../../service/common.js';

const styles = css({
    italic: {
        fontStyle: 'italic'
    },
    invalidTxt: {
        color: 'red'
    },
    headerTexth1: {
        fontSize: '2rem'
    },    
    headerTexth4: {
        fontSize: '18px'
    }
});

 export function renderNotAvailable() {
    return (
        <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
            <section className="gradient_color">
                <div className="container">
                    <div className="row P_30_T">
                        <div className="logo text-center">
                            <div className="logo text-center"><img alt="logo" className="img-fluid" width="70px" src={OCS_LOGO} /></div>
                        </div>
                    </div>
                    <div className="row P_30_T">
                        <div className="text-center">
                            <h1 style={styles.headerTexth1}>ONCALL - SKILLS & KNOWLEDGE ASSESSMENT </h1>
                        </div>  
                    </div>
                    <div className="text-center slds-p-top_large">
                        <h4 style={styles.headerTexth4}>Assessment evaluation screen is available only for submitted assessment.</h4>
                        <p class="slds-p-top_small">Please try after assessment submitted.</p>
                    </div>
                </div>
            </section>
        </IconSettings>
    );
}