import React, { Component } from 'react';
import jQuery from "jquery";
import Select from 'react-select-plus';
import BlockUi from 'react-block-ui';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-block-ui/style.css';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { checkItsNotLoggedIn, postData, toastMessageShow, queryOptionData, comboboxFilterAndLimit } from 'service/common.js';
import '../../scss/components/admin/salesforce/lightning/salesforce-lightning-design-system.scss';
import SLDSReactSelect from 'components/admin/salesforce/lightning/SLDSReactSelect.jsx';
import {
    Modal,
    Checkbox,
    Button,
    IconSettings,
    Combobox,
    Icon
} from '@salesforce/design-system-react';
/**
 * Class: CreateDocumentModel
 */
class CreateDocumentModel extends Component {
    constructor(props) {
        super(props);
        // Check user is logged in or not
        checkItsNotLoggedIn();
        // Initialize state
        this.state = {
            loading: false,
            redirectPage: false,
            title: '',
            issue_date_mandatory: false,
            expire_date_mandatory: false,
            reference_number_mandatory: false,
            active: true,
            doc_fetched_category_options: [],
            doc_matrix_category_options: [],
            doc_related_to_options: [],
            doc_related_to_selection: [],
            doc_related_to_selection_ids: [],
            mandatory: true,
            showDocMandatory: false,
            is_document_common_for_all_job: true,
            showDocCommonForAllJob: false
        }
    }

    /**
     * Update the state value of input
     * @param {Obj} e
     */
    handleChange = (e) => {
        var state = {};
        state[e.target.name] = !this.state[e.target.name];
        this.setState(state);
    }

    handleDropChange = (value, key) => {
        this.setState({ [key]: value });
    }

    /**
     * Update the state value of Select option
     * @param {Obj} selectedOption
     * @param {str} fieldname
     */
    selectChange = (selectedOption, fieldname) => {
        var state = {};
        state[fieldname] = selectedOption;
        state[fieldname + '_error'] = false;

        this.setState(state);
    }

    getOptionForDocumentCategory = () => {
        postData('item/Document/get_document_details', {}).then((result) => {
            if (result.status) {
                this.setState({
                    doc_fetched_category_options: (result.data.doc_fetched_category_options) ? result.data.doc_fetched_category_options : [],
                    doc_related_to_options: (result.data.doc_related_to_options) ? result.data.doc_related_to_options : [],
                    doc_matrix_category_options: (result.data.doc_matrix_category_options) ? result.data.doc_matrix_category_options : [],
                    //language_options: (res.data.language)?res.data.language:[],
                    //transport_options: (res.data.language)?res.data.transport:[]
                })
            }
        });
    }

    componentDidMount() {
        this.getOptionForDocumentCategory();

    }

    checkDocFetchedDetails = (selected_val) => {
        this.state.doc_fetched_category_options.map((val) => {
            if (selected_val == val.value && val.label == 'Docs Required (Recruitment Stage)') {
                this.setState({ showDocMandatory: true });
            } else {
                this.setState({ showDocMandatory: false });
            }
        });
    }

    checkDocRelatedTypeDetails = () => {
        let is_docs = this.state.doc_related_to_selection.filter(x => x.label === "Recruitment");
        if (is_docs && is_docs.length > 0) {
            this.setState({ showDocCommonForAllJob: true, is_document_common_for_all_job: true });
        } else {
            this.setState({ showDocCommonForAllJob: false, is_document_common_for_all_job: false });
        }

    }

    /**
     * Call the create api when user save document
     * Method - POST
     * @param {Obj} e
     */
    submit = (e) => {
        e.preventDefault();
        jQuery("#create_document").validate({ /* */ });
        var url = 'item/Document/create_document';
        var validator = jQuery("#create_document").validate({ ignore: [] });

        var title = this.state.title;
        var matches_name = new RegExp(/^[a-zA-Z\s_\-0-9]+$/);

        if (matches_name.test(title) === false) {
            toastMessageShow("Name must contain only alphabetical & numeral characters", "e");
            return false;
        }

        if (this.state.doc_related_to_selection.length === 0) {
            toastMessageShow("Please select related to ", "e");
            return false;
        }

        // Allow only validation is passed
        if (!this.state.loading && jQuery("#create_document").valid()) {

            this.setState({ loading: true });
            var req = {
                ...this.state,
                active: this.state.active ? 1 : 0,
                issue_date_mandatory: this.state.issue_date_mandatory ? 1 : 0,
                expire_date_mandatory: this.state.expire_date_mandatory ? 1 : 0,
                reference_number_mandatory: this.state.reference_number_mandatory ? 1 : 0,
                doc_related_to_selection: this.state.doc_related_to_selection ? this.state.doc_related_to_selection : '',
                doc_category: this.state.doc_category ? this.state.doc_category : '',
                doc_matrix_category: this.state.doc_matrix_category ? this.state.doc_matrix_category : '',
            };
            // Call Api
            postData(url, req).then((result) => {
                if (result.status) {
                    let msg = result.hasOwnProperty('msg') ? result.msg : '';
                    let document_id = '';
                    if (result.data) {
                        let resultData = result.data;
                        document_id = resultData.document_id || '';
                    }
                    // Trigger success pop
                    toastMessageShow(result.msg, 's');
                    this.props.closeModal(true);

                } else {
                    // Trigger error pop
                    toastMessageShow(result.error, "e");
                }
                this.setState({ loading: false });
            });
        } else {
            // Validation is failed
            validator.focusInvalid();
        }
    }

    /**
    * Rendering likes, transport and language multi selection boxes
    * @param {str} label
    * @param {str} stateName - selection state
    * @param {str} valueName - value state
    */
    renderCCComboBox = (id, selection_options, stateName, valueName) => {
        return (
            <Combobox
                id={id}
                predefinedOptionsOnly
                disabled={this.props.disabled}
                events={{
                    onChange: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({ [valueName]: value }, () => {
                            this.checkDocRelatedTypeDetails();
                        });
                    },
                    onRequestRemoveSelectedOption: (event, data) => {
                        this.setState({
                            [valueName]: '',
                            [stateName]: data.selection,
                            mandatory: (data.selection.length == 0) ? true : false
                        }, () => {
                            this.checkDocRelatedTypeDetails();
                        });

                    },
                    onSubmit: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({
                            [valueName]: '',
                            [stateName]: [
                                ...this.state[stateName],
                                {
                                    label: value,
                                    icon: (
                                        <Icon
                                            assistiveText={{ label: 'Account' }}
                                            category="standard"
                                            name="account"
                                        />
                                    ),
                                },
                            ],
                            mandatory: (this.value.length == 0) ? true : false
                        });
                    },
                    onSelect: (event, data) => {
                        if (this.props.action) {
                            this.props.action('onSelect')(
                                event,
                                ...Object.keys(data).map((key) => data[key])
                            );
                        }
                        this.setState({
                            [valueName]: '',
                            [stateName]: data.selection,
                            mandatory: false
                        }, () => {
                            this.checkDocRelatedTypeDetails();
                        });

                    },
                }}

                menuMaxWidth="500px"
                menuItemVisibleLength={5}
                multiple
                required={this.state.mandatory}
                options={comboboxFilterAndLimit(
                    this.state[valueName],
                    5,
                    this.state[selection_options],
                    this.state[stateName],
                )}
                selection={this.state[stateName]}
                value={this.state[valueName]}
            />
        );
    }

    /**
     * Render the display content
     */
    render() {
        //var doc_related_to_option = [{ value: "1", label: "Recuirment" }, { value: "2", label: "Member" }]
        return (
            <React.Fragment>
                <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                    <Modal
                        isOpen={this.props.showModal}
                        footer={[
                            <Button disabled={this.state.loading} key={0} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                            <Button disabled={this.state.loading} key={1} label="Save" variant="brand" onClick={this.submit} />,
                        ]}
                        heading={"New Document"}
                        size="small"
                        className="slds-modal slds_custom_modal slds_custom_modal_cont_visible"
                        onRequestClose={() => this.props.closeModal(false)}
                        ariaHideApp={false}
                        dismissOnClickOutside={false}
                    >
                        <section className="manage_top" >
                            <div className="container-fluid">
                                <form id="create_document" autoComplete="off" className="slds_form">
                                    <div className="row py-2">
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                                    <abbr className="slds-required" title="required">* </abbr>Document Name</label>
                                                <div className="slds-form-element__control">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        placeholder=""
                                                        required={true}
                                                        className="slds-input"
                                                        onChange={(value) => this.setState({ title: value.target.value })}
                                                        value={this.state.title || ''}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Document Fetched During</label>
                                                <div className="slds-form-element__control" role="none">
                                                    <div className="">
                                                        <SLDSReactSelect
                                                            simpleValue={true}
                                                            className="custom_select default_validation"
                                                            options={this.state.doc_fetched_category_options}
                                                            onChange={(value) => {
                                                                this.handleDropChange(value, 'doc_category');
                                                                this.checkDocFetchedDetails(value);
                                                            }}
                                                            value={this.state.doc_category || ''}
                                                            clearable={true}
                                                            searchable={false}
                                                            placeholder="Please Select"
                                                            name="doc_category"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row py-2">
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">
                                                    {this.state.showDocMandatory && <abbr className="slds-required" title="required">* </abbr>}Document Category</label>
                                                <div className="slds-form-element__control" role="none">
                                                    <div className="">
                                                        <SLDSReactSelect
                                                            simpleValue={true}
                                                            className="custom_select default_validation"
                                                            options={this.state.doc_matrix_category_options}
                                                            onChange={(value) => this.handleDropChange(value, 'doc_matrix_category')}
                                                            value={this.state.doc_matrix_category || ''}
                                                            clearable={true}
                                                            searchable={false}
                                                            placeholder="Please Select"
                                                            name="doc_matrix_category"
                                                            required={this.state.showDocMandatory}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <abbr className="slds-required" title="required">* </abbr>
                                                <label className="slds-form-element__label" htmlFor="select-01">Related to</label>
                                                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        {this.renderCCComboBox("doc_related_to", "doc_related_to_options", "doc_related_to_selection", "doc_related_to_selection_ids")}
                                                    </IconSettings>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-2">
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-3">
                                                    <abbr className="slds-required" title="required"></abbr>Issue Date Mandatory</label>
                                                <div className="slds-form-element__control">
                                                    <Checkbox
                                                        assistiveText={{
                                                            label: '',
                                                        }}
                                                        id="issue_date_mandatory"
                                                        labels={{
                                                            label: '',
                                                        }}
                                                        checked={this.state.issue_date_mandatory}
                                                        name="issue_date_mandatory"
                                                        onChange={(value) => this.handleChange(value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-4">
                                                    <abbr className="slds-required" title="required"></abbr>Expire Date Mandatory</label>
                                                <div className="slds-form-element__control">
                                                    <Checkbox
                                                        assistiveText={{
                                                            label: '',
                                                        }}
                                                        id="expire_date_mandatory"
                                                        labels={{
                                                            label: '',
                                                        }}
                                                        checked={this.state.expire_date_mandatory}
                                                        name="expire_date_mandatory"
                                                        onChange={(value) => this.handleChange(value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-3">
                                                    <abbr className="slds-required" title="required"></abbr>Reference Number Mandatory</label>
                                                <div className="slds-form-element__control">
                                                    <Checkbox
                                                        assistiveText={{
                                                            label: '',
                                                        }}
                                                        id="reference_number_mandatory"
                                                        labels={{
                                                            label: '',
                                                        }}
                                                        checked={this.state.reference_number_mandatory}
                                                        name="reference_number_mandatory"
                                                        onChange={(value) => this.handleChange(value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.showDocCommonForAllJob && <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-2">
                                                    <abbr className="slds-required" title="required"></abbr>Document Common For All Jobs?</label>
                                                <div className="slds-form-element__control">
                                                    <Checkbox
                                                        assistiveText={{
                                                            label: '',
                                                        }}
                                                        id="is_document_common_for_all_job"
                                                        labels={{
                                                            label: '',
                                                        }}
                                                        name="is_document_common_for_all_job"
                                                        checked={this.state.is_document_common_for_all_job}
                                                        onChange={(value) => this.handleChange(value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                        {!this.state.showDocCommonForAllJob && <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-2">
                                                    <abbr className="slds-required" title="required"></abbr>Active</label>
                                                <div className="slds-form-element__control">
                                                    <Checkbox
                                                        assistiveText={{
                                                            label: '',
                                                        }}
                                                        id="active"
                                                        labels={{
                                                            label: '',
                                                        }}
                                                        name="active"
                                                        checked={this.state.active}
                                                        onChange={(value) => this.handleChange(value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="row py-2">
                                        {this.state.showDocCommonForAllJob && <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-2">
                                                    <abbr className="slds-required" title="required"></abbr>Active</label>
                                                <div className="slds-form-element__control">
                                                    <Checkbox
                                                        assistiveText={{
                                                            label: '',
                                                        }}
                                                        id="active"
                                                        labels={{
                                                            label: '',
                                                        }}
                                                        name="active"
                                                        checked={this.state.active}
                                                        onChange={(value) => this.handleChange(value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </form>
                            </div>
                        </section>
                    </Modal>
                </IconSettings>
            </React.Fragment>
        );
    }
}

export default CreateDocumentModel;
